<template>
  <div>
    <b-row v-if="shopsComputed.length > 0">
      <b-col
        v-for="(shop, index) in shopsComputed"
        :key="index"
        md="6"
        lg="4"
        xl="3"
      >
        <b-card
          class="card-employee-task"
        >
          <div class="d-flex justify-content-between">
            <div>
              <b-card-title>{{ shop.nome }}</b-card-title>
              <b-card-sub-title>{{ shop.indirizzo }} </b-card-sub-title>
            </div>
            <div>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- <b-dropdown-item>
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span>Carica Merce</span>
                </b-dropdown-item> -->
                <b-dropdown-item
                  :to="{ name: 'datails-organization', params: { id: shop.id } }"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Dettagli</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Magazzino</span>
                </b-dropdown-item> -->
                <!-- <b-dropdown-item
                  @click="deleteOrganization(shop.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Elimina</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>
          <b-card-header>
            <!-- <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="cursor-pointer"
            /> -->

          </b-card-header>

          <!-- body -->
          <b-card-body>
            <div class="mb-2">
              <!-- <vue-apex-charts
                type="donut"
                height="350"
                :options="apexChatData.donutChart.chartOptions"
                :series="apexChatData.donutChart.series"
              /> -->
              <!-- <activeVisitor /> -->
            </div>
            <b-card-text>
              <ul class="list-unstyled">
                <li class="d-flex justify-content-between">
                  <strong>
                    Responsabile:
                  </strong>
                  <span>
                    {{ shop.responsabile }}
                  </span>
                </li>
                <li class="d-flex justify-content-between">
                  <strong>
                    Email:
                  </strong>
                  <span>
                    {{ shop.email }}
                  </span>
                </li>
                <li class="d-flex justify-content-between">
                  <strong>
                    Recapito:
                  </strong>
                  <span>
                    {{ shop.telefono }}
                  </span>
                </li>
                <li class="d-flex justify-content-between">
                  <strong>
                    Altre info:
                  </strong>
                  <span>
                    {{ shop.descrizione }}
                  </span>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
          <div class="d-flex">
            <b-button
              variant="primary"
              tag="a"
              class="btn-cart d-block"
              :to="{ name: 'datails-organization', params: { id: shop.id }}"
              style="border-top-left-radius: 0 !important;border-top-right-radius: 0;important; width: 100%"
            >
              <img
                :src="require('@/@core/assets/iconpro/'+ 'circle-info' +'.svg')"
                width="20"
                height="20"
                class="mr-1"
              >
              <span>Dettagli</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BCardTitle, BDropdown, BDropdownItem, BCardBody, BCardHeader, BCardSubTitle, BCardText,
} from 'bootstrap-vue'
import { getPuntiVendita, deletePuntoVendita } from '@/@core/api-service/logistic/puntiVendita'
import apexChatData from './apexChartData'
// import activeVisitor from './shopStats/activeVisitors.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    // VueApexCharts,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardHeader,
    // activeVisitor,
    BCardSubTitle,
    BCardText,
  },
  data() {
    return {
      shops: [],
      apexChatData,
      /* donutChart: {
        series: [85, 16, 50, 50],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Operational',
                    formatter() {
                      return '31%'
                    },
                  },
                },
              },
            },
          },
        },
      }, */
    }
  },
  computed: {
    shopsComputed() {
      return this.shops
    },
  },
  mounted() {
    getPuntiVendita()
      .then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.shops = [...res.data.puntivendita]
        }
      })
      .catch(e => e)
  },
  methods: {
    deleteOrganization(id) {
      // console.log('id', id)
      const shopToDelete = JSON.stringify([{ id }])
      // console.log('shopToDelete', shopToDelete)
      deletePuntoVendita(shopToDelete).then(res => res).catch(e => e)
    },
  },
}
</script>

<style scoped>
</style>
